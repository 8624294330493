import { useEffect, useState, useCallback, useMemo } from 'react';
import { toast } from 'react-toastify';
import { Icon, Button, Form, FormGroup, Label , Input, InputGroup, InputGroupAddon ,Switch, Row, Col, Modal, ModalBody, ModalFooter , UncontrolledTooltip, CustomInput, Link } from '@polichat/flamboyant';

import CreatableSelect from 'react-select/creatable';
import ReactSelect from 'react-select';

import { useLocation } from 'react-router-dom';

import { useSelector, useDispatch } from 'react-redux'

import PoliRDSImg from '../../../../assets/images/integrations/Poli_RDS.svg';

import  PageMainHeader from '../../../common/pages/PageMainHeader/PageMainHeader.component';
import  PageMainBody  from '../../../common/pages/PageMainBody/PageMainBody.component';
import  Animation from '../../../common/animation';

import history from '../../../../services/history';
import useRDStation from './useRDStation';
import useModal from './useModal';

import { useIntegrationService } from '../useIntegrationService';

import { StatusContainer, ButtonsContainer, LabelContainer} from './RDStationIntegration.styles'
import integrations from '../integrations-driver';

import { fetchQuickMessages } from '../../../../store/modules/chat/actions';

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

function RDStationIntegration({ data, action }) {

  const [useIntegrationData, setUseIntegrationData] = useState(data);

  let querySearch = useQuery();

  const {isOpen: isOpenDisconnect , onOpenRequest: onOpenDisconnect, onCloseRequest: onCloseDisconnect} = useModal();

  const [onOpenChatContact, setOnOpenChatContact] = useState(true);
  const [onCloseChatContact, setOnCloseChatContact] = useState(true);
  const [onChangeContact, setOnChangeContact] = useState(true);
  const [onShoppingOrderStatusChange, setOnShoppingOrderStatusChange] = useState(true);
  const [onConversionContact, setOnConversionContact] = useState(false);
  const [conversionContactEvents, setConversionContactEvents] = useState([]);
  const [autoFlowWebhooks, setAutoFlowWebhooks] = useState(useIntegrationData?.config?.webhook?.autoFlowMessageTemplates ? useIntegrationData?.config?.webhook?.autoFlowMessageTemplates  : []);
  const [ msgTemplates, setMsgTemplates] = useState([]);

  const quickMessages = useSelector((state) => state.chat.quickMessages);

  const dispatch = useDispatch();

  const {
    isLoading: isLoadingService,
    isError,
    isSuccess,
    isDeleted,
    saveIntegrationData,
    deleteIntegrationData,
    authUrl,
    genAuthUrl,
    integrationData,
    getIntegrationData
  } = useIntegrationService();

  const {
    status: integrationStatus,
  } = useRDStation({integrationDataReceived: useIntegrationData});

  const handleSwitchDisconnect = () => {
    deleteIntegrationData(integrations.RDSTATION);
  }

  const handleCancel = (e) => {
    e.preventDefault();
    e.stopPropagation();
    history.goBack();
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    let autoFlowWebhooksList = [];
    if(integrationStatus.status === 'on')
    {
        for(let i = 0; i < autoFlowWebhooks.length; i++)
      {
        let useFlow = autoFlowWebhooks[i];

        if(!useFlow.template)
        {
          return toast.warning('Preencha os campos corretamente. Informe um template');
        }
        else
        {
          autoFlowWebhooksList.push(useFlow);
        }
      }
    }

    let conversionContactEventsList = [];
    for(let i = 0; i < conversionContactEvents.length; i++)
    {
      let useEvent = conversionContactEvents[i];
      if(!conversionContactEventsList.includes(useEvent.value))
      {
        conversionContactEventsList.push(useEvent.value);
      }
    }

    let integrationParams = {
      driver: integrations.RDSTATION,
      config: {
        onOpenChatContact,
        onCloseChatContact,
        onChangeContact,
        onConversionContact,
        onShoppingOrderStatusChange,
        conversionContactEvents: conversionContactEventsList,
        autoFlowMessageTemplateWebhooks: autoFlowWebhooksList,
      }
    }

    if(useIntegrationData.id) //update
    {
      saveIntegrationData(integrationParams);
    }
    else //create
    {
      localStorage.setItem('rdstation_code', null);
      window.open(authUrl);

      let intervalCode = setInterval(() => {

        let RDCode = localStorage.getItem('rdstation_code');
        if(RDCode != 'null'){

          integrationParams.authCode = RDCode;

          clearInterval(intervalCode);
          saveIntegrationData(integrationParams);
        }
      }, 2000)
    }

  }

  useEffect(() => {

    if(action == "authcallback")
    {
      localStorage.setItem('rdstation_code', querySearch.get('code'));
      window.close();
    }
    else
    {
      genAuthUrl(integrations.RDSTATION);
    }

    if(!quickMessages)
    {
      console.log("dispatch quickMessages");
      dispatch(fetchQuickMessages());
      dispatch(fetchQuickMessages());
      dispatch(fetchQuickMessages());
    }


  }, []);

  useEffect(() => {

   let template_options = [];
   if(quickMessages && quickMessages?.messages?.length > 0)
   {
    for(let i = 0; i < quickMessages?.messages.length; i++)
    {
      let useMessage = quickMessages?.messages[i];

      if(useMessage && useMessage.template && useMessage.validated == 1)
      {
        template_options.push({
          label: useMessage.tag,
          value: 1,
          template: useMessage
        })
      }
    }
   }

   setMsgTemplates(template_options);

  }, [quickMessages])

  useEffect(() => {
    //request post response error
    if(isError && !useIntegrationData?.id){
      setUseIntegrationData({error:  isError})
    }

    //request post response success
    if(isSuccess) {
      setUseIntegrationData(integrationData)
      setAutoFlowWebhooks(integrationData.config?.webhook?.autoFlowMessageTemplates ? integrationData.config?.webhook?.autoFlowMessageTemplates : []);
    }
  }, [isError, isSuccess, integrationData]);

  useEffect(() => {
      if(useIntegrationData.config)
      {
        let conversionEvents = [];
        if ((typeof useIntegrationData === 'object') && (typeof useIntegrationData.config === 'object')) {
          if ((useIntegrationData.config.conversionContactEvents)
            && (useIntegrationData.config.conversionContactEvents.length > 0)) {
            for (let i = 0; i < useIntegrationData.config.conversionContactEvents.length; i++) {
              const useEvent = useIntegrationData.config.conversionContactEvents[i];
              if (useEvent) conversionEvents.push({ label: useEvent, value: useEvent, __isNew__: true })
            }
          }
          if(useIntegrationData.config.hasOwnProperty("onOpenChatContact")) setOnOpenChatContact(useIntegrationData.config.onOpenChatContact);
          if(useIntegrationData.config.hasOwnProperty("onCloseChatContact")) setOnCloseChatContact(useIntegrationData.config.onCloseChatContact);
          if(useIntegrationData.config.hasOwnProperty("onChangeContact")) setOnChangeContact(useIntegrationData.config.onChangeContact);
          if(useIntegrationData.config.hasOwnProperty("onConversionContact")) setOnConversionContact(useIntegrationData.config.onConversionContact);
          if(useIntegrationData.config.hasOwnProperty("onShoppingOrderStatusChange")) setOnShoppingOrderStatusChange(useIntegrationData.config.onShoppingOrderStatusChange);
        }
        setConversionContactEvents(conversionEvents);
      }

  }, [useIntegrationData]);

  useEffect(() => {
    if(isDeleted){
      history.goBack();
    }
  }, [isDeleted])

  return (
    <>
      <PageMainHeader
        title="Integrações"
        icon="poli-icon pi-integrations-line"
      />
      <PageMainBody>
        <StatusContainer color={integrationStatus.statusColor}>
          <img src={PoliRDSImg} />
          {isLoadingService ? <Animation icon='chat-loading' size={110} /> : (
          <>
            <h2>{integrationStatus.message?.[0] || 'Poli + RD Station'}</h2>
            <h3>{integrationStatus.message?.[1] || ''}</h3>
          </>
          )}

            {integrationStatus.status === 'on' ?
            (<>
            <Row style={{marginTop: '2rem'}}>
              <Col>
                <FormGroup>
                <Button
                  color="danger"
                  size={""}
                  title="Desconectar"
                  id="button-tooltip"
                  onClick={onOpenDisconnect}
                >
                   Desconectar
                  <Icon
                    position={"relative"}

                    left={"5px"}
                    size={22}
                    icon={"poli-icon pi-integrations-line"}
                  />

                </Button>
                <UncontrolledTooltip
                  placement="top"
                  target="button-tooltip"
                >
                  Desconectar
                </UncontrolledTooltip>
              </FormGroup>
              </Col>
            </Row>
            </>)
            :
            null }
        </StatusContainer>

        <Form onSubmit={handleSubmit} className="col">

          <h3 style={{marginBottom: '1rem'}}>
            <Icon
              position={"relative"}

              right={"5px"}
              size={22}
              icon={"poli-icon pi-preferences-fill"}
            />
            <strong>Opções de Integrações</strong>
          </h3>
          <Row>
            <Col sm="12">
              <FormGroup>
                <LabelContainer>
                  <CustomInput
                    id={"rdOnOpenChatContact"}
                    className="card__checkbox"
                    type="checkbox"
                    name="rdOnOpenChatContact"
                    checked={onOpenChatContact}
                    onChange={
                      (e) => {
                        setOnOpenChatContact(e.target.checked)
                      }}
                  />
                  <Label>
                    Ao Iniciar um Chat na Poli, enviar evento de início de conversa para o RDStation.
                  </Label>
                </LabelContainer>
              </FormGroup>
            </Col>
            <Col sm="12">
              <FormGroup>
                <LabelContainer>
                  <CustomInput
                    id={"rdOnCloseChatContact"}
                    className="card__checkbox"
                    type="checkbox"
                    name="rdOnCloseChatContact"
                    checked={onCloseChatContact}
                    onChange={
                      (e) => {
                        setOnCloseChatContact(e.target.checked)
                      }}
                  />
                  <Label>
                  Ao Finalizar um Chat na Poli, enviar evento de término de conversa para o RDStation.
                  </Label>
                </LabelContainer>
              </FormGroup>
            </Col>
            <Col sm="12">
              <FormGroup>
                <LabelContainer>
                  <CustomInput
                    id={"rdOnChangeContact"}
                    className="card__checkbox"
                    type="checkbox"
                    name="rdOnChangeContact"
                    checked={onChangeContact}
                    onChange={
                      (e) => {
                        setOnChangeContact(e.target.checked)
                      }}
                  />
                  <Label>
                    Ao Incluir/Alterar Contato na Poli, enviar dados do contato para o RDStation.
                  </Label>
                </LabelContainer>
              </FormGroup>
            </Col>

            <Col sm="12">
              <FormGroup>
                <LabelContainer>
                  <CustomInput
                    id={"rdOnShoppingOrderStatusChange"}
                    className="card__checkbox"
                    type="checkbox"
                    name="rdOnShoppingOrderStatusChange"
                    checked={onShoppingOrderStatusChange}
                    onChange={
                      (e) => {
                        setOnShoppingOrderStatusChange(e.target.checked)
                      }}
                  />
                  <Label>
                      Enviar eventos de Pedidos/Pagamentos do PoliPay para o RDStation.
                  </Label>
                </LabelContainer>
              </FormGroup>
            </Col>
            </Row>
            <FormGroup>

            <Row>
            <Col sm="12">

                <LabelContainer>
                  <CustomInput
                    id={"rdOnConversionContact"}
                    className="card__checkbox"
                    type="checkbox"
                    name="rdOnConversionContact"
                    checked={onConversionContact}
                    onChange={
                      (e) => {
                        setOnConversionContact(e.target.checked)
                      }}
                  />
                  <Label>
                    Em uma conversão do Lead no RDStation, Incluir/Alterar Contato na Poli.
                  </Label>

                </LabelContainer>

            </Col>
            </Row>

            { onConversionContact ?
             <>
            <Row>
                <Col sm="12">
                <Label>
                        Informe em quais eventos de conversão haverá a integração.
                      </Label>
                  </Col>
                  </Row>

            <Row>
                <Col sm="6">

                       <CreatableSelect

                         formatCreateLabel={(text) => 'Adicionar "'+(text ? text : "")+'"'}
                         noOptionsMessage={(text) =>"Digite o evento e confirme clicando em Adicionar ou pressione Enter"}
                         isMulti
                         isClearable
                         placeholder="Adicione Eventos"
                         onChange={(e) => {setConversionContactEvents(e)}}
                         //onInputChange={(e) => {console.log("input change", e);}}
                         value={conversionContactEvents}
                         options={[]}
                       />

            </Col>
            </Row>
            </>
             : null}
            </FormGroup>


            {integrationStatus.status === 'on' ?
      <>
          <Row style={{marginTop: '4rem'}}>
            <Col sm="12">
              <h3 style={{marginBottom: '1rem'}}>
              <Icon
                position={"relative"}

                right={"5px"}
                size={22}
                icon={"poli-icon pi-preferences-fill"}
              />
              <strong> Fluxos de Automação RDStation</strong>
            </h3>

            </Col>
            <Col sm="12">
            <LabelContainer>
              <Icon
                position={"relative"}

                right={"5px"}
                size={22}
                icon={"poli-icon pi-whatsapp-line"}
              />
            <Label>
            Envio de Mensagem via WhatsApp
                  </Label>
                  </LabelContainer>
            </Col>
            <Col sm="12"> <small>
              Informe um template de mensagem para utilizar no fluxo de automação do RDStation através da ação "Enviar Leads para Integração".
              <br/>
              Uma url de integração será gerada para cada template após realizar a conexão ou salvar estas configurações.
            </small></Col>
          </Row>

            {autoFlowWebhooks.map((e, i) => {

                  return(
                    <Row style={{marginTop: '1rem'}}>
                      <Col sm="3">
                        <ReactSelect
                          isClearable={true}
                          isSearchable={true}
                          options={msgTemplates}
                          className="basic-single"
                          classNamePrefix="select"
                          value={e.template ? {label: e.template.tag, template: e.template} : null}
                          placeholder="Adicionar Template"
                          name={"template"+i}
                          onChange={((e) => {

                            let autoFlowWebhooksAux = [...autoFlowWebhooks];
                            autoFlowWebhooksAux[i].template = e ? e.template : null;
                            setAutoFlowWebhooks(autoFlowWebhooksAux);
                           })}
                        />

                      </Col>

                      { e.url ?
                      <>
                          <Col sm="7"  style={{fontSize:"12px"}}>
                            {e.url}

                          </Col>
                          <Col sm="1">
                          <Button
                              gradient="primary"
                              width="30px"
                              title="Copiar"
                              onClick={()=>{
                                if(autoFlowWebhooks[i].url)
                                {
                                  const el = document.createElement('textarea');
                                  el.value = autoFlowWebhooks[i].url;
                                  el.setAttribute('readonly', '');
                                  el.style.position = 'absolute';
                                  el.style.left = '-9999px';
                                  document.body.appendChild(el);
                                  el.select();
                                  document.execCommand('copy');
                                  document.body.removeChild(el);

                                  toast.info('Link Copiado!');
                                }

                              }}
                            >Copiar
                          </Button>
                      </Col>
                      </>
                      : <></>
                      }

                      <Col sm="1">
                          <Button
                              width="30px"
                              title="Remover"
                              color="danger"
                              onClick={()=>{


                                let autoFlowWebhooksAux = [...autoFlowWebhooks];
                                autoFlowWebhooksAux.splice(i, 1);
                                setAutoFlowWebhooks(autoFlowWebhooksAux)

                              }}
                            >X
                          </Button>
                      </Col>

                    </Row>
                  )
            })}
            <Row style={{marginTop: '1rem'}}>
              <Col sm="12">
                <a href="#" onClick={()=>{
                  let autoFlowWebhooksAux = [...autoFlowWebhooks, {  template: null, url: null }];
                  setAutoFlowWebhooks(autoFlowWebhooksAux)
                }}>Adicionar +</a>

              </Col>
            </Row>

          </>
          : <></> }


            <Row style={{marginTop: '7rem', marginBottom: '3rem'}}>
            <ButtonsContainer>
            {integrationStatus.status === 'on' ?
                <Button

                  gradient="primary"
                  width="quarter"
                  title="confirmar"
                >Salvar Alterações</Button>
                :
                <>
                <Button
                  color="success"
                  size={""}
                  title="Conectar"
                  id="button-tooltip"
                >
                   Conectar
                  <Icon
                    position={"relative"}

                    left={"5px"}
                    size={22}
                    icon={"poli-icon pi-integrations-line"}
                  />

                </Button>
                <UncontrolledTooltip
                  placement="top"
                  target="button-tooltip"
                >
                  Conectar
                </UncontrolledTooltip>
                </>
                 }

                <Button color="danger" onClick={handleCancel}>Cancelar</Button>
            </ButtonsContainer>
          </Row>

        </Form>

      </PageMainBody>
      {/* Remove connection confirmation */}
      <Modal isOpen={isOpenDisconnect} toggle={onCloseDisconnect}>
        <ModalBody>
          Deseja desconectar sua integração com o RDStation?
        </ModalBody>
        <ModalFooter>
          <Button outline color="primary" onClick={onCloseDisconnect}>Cancelar</Button>
          <Button color="danger" onClick={handleSwitchDisconnect}>Desconectar</Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default RDStationIntegration;
