import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CustomInput,
  Button,
} from '@polichat/flamboyant';
import { useState } from 'react';

import PoliZAPI from '../../../../assets/images/integrations/Poli_ZAPI.svg';
import PoliZAPIW from '../../../../assets/images/integrations/Poli_ZAPI_white.svg';

import styles from './style.module.css';

interface ZapiModalProps {
  isOpen: boolean;
  isDarkMode: boolean;
  onClose: () => void;
  onSubmit: () => void;
  isFreeTrial: boolean;
}

export default function ZapiModal({
  isOpen,
  isDarkMode,
  onClose,
  onSubmit,
  isFreeTrial,
}: ZapiModalProps): JSX.Element {
  const [isChecked, setIsChecked] = useState(false);

  const handleAgree = () => {
    setIsChecked((state: any) => (state = !state));
  };

  return (
    <>
      <Modal isOpen={isOpen} toggle={onClose}>
        <ModalHeader>
          <div className={styles.image_header}>
            <img src={isDarkMode ? PoliZAPIW : PoliZAPI} />
            Integração Z-API
          </div>
        </ModalHeader>
        {isFreeTrial ? (
          <>
            <ModalBody>
              Você ainda não possui uma assinatura! Para prosseguir com a
              criação desse canal, entre em contato com o seu consultor ou a
              nossa equipe de vendas.
            </ModalBody>
            <ModalFooter>
              <div className={styles.buttons_container}>
                <Button
                  gradient="danger"
                  className={styles.custom_button}
                  onClick={onClose}
                >
                  Cancelar
                </Button>
                <Button
                  className={styles.custom_button}
                  onClick={() => window.open('https://wa.me/556299800123')}
                >
                  Contratar
                </Button>
              </div>
            </ModalFooter>
          </>
        ) : (
          <>
            <ModalBody>
              Você conectará um canal de WhatsApp de uma plataforma externa e
              não a API de WhatsApp oferecida pela Poli. Você deseja continuar?
            </ModalBody>
            <ModalBody>
              <div className={styles.checkbox_container}>
                <CustomInput
                  type="checkbox"
                  id="show-widget-at-webchat"
                  label="Li e estou ciente dos termos da conexão com uma API externa de WhatsApp."
                  checked={isChecked}
                  onChange={handleAgree}
                />
              </div>
            </ModalBody>
            <ModalFooter>
              <div className={styles.buttons_container}>
                <Button
                  gradient="danger"
                  className={styles.custom_button}
                  onClick={onClose}
                >
                  Cancelar
                </Button>
                <Button
                  disabled={!isChecked}
                  onClick={onSubmit}
                  className={styles.custom_button}
                >
                  Continuar
                </Button>
              </div>
            </ModalFooter>
          </>
        )}
      </Modal>
    </>
  );
}
