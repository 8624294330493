import React, { useState } from 'react';
import { LetterAvatar } from '@polichat/flamboyant';
import checkAvatar from '../../../utils/checkAvatar';

function Avatar({ className, url, size, alt, title, onClick, cursor }) {
  const personCursor = {
    avatar: {
      borderRadius: '50%',
      cursor: cursor,
    },
  };

  if (!!url && url.substring(0, 8) !== 'https://') {
    return (
      <div
        className={`letterAvatarContainer ${className}`}
        style={cursor ? personCursor.avatar : styles.avatar}
        title={title}
      >
        <LetterAvatar url={url} size={size} radius={(size * 1) / 2} />
      </div>
    );
  } else {
    const [error, setError] = useState(false);

    const onError = () => {
      if (!error) setError(true);
    };

    const renderImg = (
      <img
        src={url}
        onError={onError}
        style={cursor ? personCursor.avatar : styles.avatar}
        width={size}
        height={size}
        title={title}
      />
    );

    const renderLetter = () => {
      const newUrl = checkAvatar(title ? title : alt);
      return (
        <div
          className={`letterAvatarContainer ${className}`}
          style={cursor ? personCursor.avatar : styles.avatar}
          title={title}
        >
          <LetterAvatar
            url={newUrl ? newUrl : ''}
            size={size}
            radius={(size * 1) / 2}
          />
        </div>
      );
    };

    if (onClick && !error) {
      return <div onClick={onClick}>{renderImg}</div>;
    } else {
      return error ? renderLetter() : renderImg;
    }
  }
}

const styles = {
  avatar: {
    borderRadius: '50%',
    cursor: 'pointer',
  },
};

export default Avatar;
