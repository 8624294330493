import React from 'react';
import { useWhiteLabel } from '../../../../../../hooks/useWhiteLabel';

function AppsIntegrations() {
  let color1 = '#0000c8';
  const whitelabel = useWhiteLabel();
  if (whitelabel.isWl) {
    if (whitelabel.cssPathWl.includes('chatshub')) {
      color1 = '#FF7038';
    }
    if (whitelabel.cssPathWl.includes('auvo')) {
      color1 = '#6d26c2';
    }
    if (whitelabel.cssPathWl.includes('nectarcrm')) {
      color1 = '#ffd55b';
    }
  }
  return (
    <svg
      className="img-fluid"
      width="300"
      height="193"
      viewBox="0 0 300 193"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.5"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M260.833 175.668C267.31 161.08 270.909 144.931 270.909 127.941C270.909 62.9219 218.201 10.2136 153.182 10.2136C88.1628 10.2136 35.4545 62.9219 35.4545 127.941C35.4545 144.931 39.0535 161.08 45.5308 175.668H260.833Z"
        fill={color1}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M195.021 74.4667H110.96C106.325 74.4667 102.553 78.2379 102.553 82.8728V166.934C102.553 171.586 106.325 175.357 110.96 175.357H195.021C199.656 175.357 203.427 171.586 203.427 166.951V82.8728C203.427 78.2379 199.656 74.4667 195.021 74.4667ZM197.828 166.934C197.828 168.479 196.583 169.742 195.021 169.742H110.959C109.414 169.742 108.152 168.496 108.152 166.934V101.895H197.828V166.934ZM117.522 84.8829H121.874C123.419 84.8829 124.682 86.201 124.682 87.8533C124.682 89.5056 123.419 90.8237 121.874 90.8237H117.522C115.96 90.8237 114.714 89.5056 114.714 87.8533C114.714 86.201 115.977 84.8829 117.522 84.8829ZM133.836 84.8829H138.189C139.75 84.8829 140.996 86.201 140.996 87.8533C140.996 89.5056 139.734 90.8237 138.189 90.8237H133.836C132.274 90.8237 131.029 89.5056 131.029 87.8533C131.029 86.201 132.291 84.8829 133.836 84.8829ZM190.32 84.8829H150.814C149.269 84.8829 148.007 86.201 148.007 87.8533C148.007 89.5056 149.252 90.8237 150.814 90.8237H190.336C191.881 90.8237 193.144 89.5056 193.144 87.8533C193.144 86.201 191.865 84.8829 190.32 84.8829Z"
        fill={color1}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M281.694 120.662H254.905C253.428 120.662 252.226 121.864 252.226 123.341V150.13C252.226 151.612 253.428 152.814 254.905 152.814H281.694C283.171 152.814 284.373 151.612 284.373 150.135V123.341C284.373 121.864 283.171 120.662 281.694 120.662ZM282.588 150.13C282.588 150.622 282.191 151.024 281.694 151.024H254.905C254.412 151.024 254.01 150.627 254.01 150.13V129.403H282.588V150.13ZM256.996 123.981H258.383C258.876 123.981 259.278 124.402 259.278 124.928C259.278 125.455 258.876 125.875 258.383 125.875H256.996C256.498 125.875 256.101 125.455 256.101 124.928C256.101 124.402 256.504 123.981 256.996 123.981ZM262.195 123.981H263.582C264.08 123.981 264.477 124.402 264.477 124.928C264.477 125.455 264.075 125.875 263.582 125.875H262.195C261.697 125.875 261.3 125.455 261.3 124.928C261.3 124.402 261.703 123.981 262.195 123.981ZM280.195 123.981H267.606C267.113 123.981 266.711 124.402 266.711 124.928C266.711 125.455 267.108 125.875 267.606 125.875H280.201C280.693 125.875 281.095 125.455 281.095 124.928C281.095 124.402 280.688 123.981 280.195 123.981Z"
        fill={color1}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M101.718 18.6628H78.6239C77.3506 18.6628 76.3145 19.6989 76.3145 20.9722V44.066C76.3145 45.3439 77.3506 46.38 78.6239 46.38H101.718C102.991 46.38 104.027 45.3439 104.027 44.0706V20.9722C104.027 19.6989 102.991 18.6628 101.718 18.6628ZM102.489 44.066C102.489 44.4905 102.147 44.8373 101.718 44.8373H78.6239C78.1994 44.8373 77.8526 44.4951 77.8526 44.066V26.198H102.489V44.066ZM80.4268 21.5244H81.6225C82.0469 21.5244 82.3938 21.8866 82.3938 22.3405C82.3938 22.7944 82.0469 23.1565 81.6225 23.1565H80.4268C79.9977 23.1565 79.6555 22.7944 79.6555 22.3405C79.6555 21.8866 80.0023 21.5244 80.4268 21.5244ZM84.9086 21.5244H86.1044C86.5334 21.5244 86.8757 21.8866 86.8757 22.3405C86.8757 22.7944 86.5288 23.1565 86.1044 23.1565H84.9086C84.4796 23.1565 84.1373 22.7944 84.1373 22.3405C84.1373 21.8866 84.4842 21.5244 84.9086 21.5244ZM100.426 21.5244H89.573C89.1485 21.5244 88.8017 21.8866 88.8017 22.3405C88.8017 22.7944 89.1439 23.1565 89.573 23.1565H100.431C100.855 23.1565 101.202 22.7944 101.202 22.3405C101.202 21.8866 100.851 21.5244 100.426 21.5244Z"
        fill={color1}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M205.887 5.63586H177.713C176.159 5.63586 174.895 6.89984 174.895 8.4533V36.6277C174.895 38.1868 176.159 39.4508 177.713 39.4508H205.887C207.44 39.4508 208.704 38.1868 208.704 36.6333V8.4533C208.705 6.89984 207.441 5.63586 205.887 5.63586ZM206.828 36.6277C206.828 37.1456 206.41 37.5687 205.887 37.5687H177.713C177.195 37.5687 176.772 37.1512 176.772 36.6277V14.8287H206.828V36.6277ZM179.912 9.12702H181.371C181.889 9.12702 182.312 9.56881 182.312 10.1226C182.312 10.6764 181.889 11.1182 181.371 11.1182H179.912C179.389 11.1182 178.971 10.6764 178.971 10.1226C178.971 9.56881 179.394 9.12702 179.912 9.12702ZM185.38 9.12702H186.839C187.362 9.12702 187.78 9.56881 187.78 10.1226C187.78 10.6764 187.357 11.1182 186.839 11.1182H185.38C184.857 11.1182 184.439 10.6764 184.439 10.1226C184.439 9.56881 184.862 9.12702 185.38 9.12702ZM204.311 9.12702H191.07C190.553 9.12702 190.129 9.56881 190.129 10.1226C190.129 10.6764 190.547 11.1182 191.07 11.1182H204.317C204.835 11.1182 205.258 10.6764 205.258 10.1226C205.258 9.56881 204.829 9.12702 204.311 9.12702Z"
        fill={`${color1}80`}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M35.4779 92.5752C27.8187 92.5752 21.6194 98.7734 21.6194 106.434C21.6194 108.865 22.2533 111.239 23.4572 113.333L21.6716 118.878C21.547 119.264 21.6494 119.688 21.9366 119.975C22.221 120.26 22.6437 120.366 23.0341 120.24L28.5791 118.454C30.673 119.658 33.0471 120.292 35.4779 120.292C43.1372 120.292 49.3365 114.094 49.3365 106.434C49.3365 98.7745 43.1383 92.5752 35.4779 92.5752ZM30.0644 107.787C30.812 107.787 31.4178 107.181 31.4178 106.434C31.4178 105.686 30.812 105.08 30.0644 105.08C29.3171 105.08 28.7111 105.686 28.7111 106.434C28.7111 107.181 29.3171 107.787 30.0644 107.787ZM40.8914 107.787C41.639 107.787 42.2448 107.181 42.2448 106.434C42.2448 105.686 41.639 105.08 40.8914 105.08C40.1439 105.08 39.5381 105.686 39.5381 106.434C39.5381 107.181 40.1439 107.787 40.8914 107.787ZM36.8313 106.434C36.8313 107.181 36.2255 107.787 35.4779 107.787C34.7304 107.787 34.1246 107.181 34.1246 106.434C34.1246 105.686 34.7304 105.08 35.4779 105.08C36.2255 105.08 36.8313 105.686 36.8313 106.434Z"
        fill={`${color1}80`}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M247.976 54.418C242.308 54.418 237.721 59.0047 237.721 64.6733C237.721 66.4721 238.19 68.2289 239.081 69.7784L237.759 73.8818C237.667 74.1678 237.743 74.4814 237.955 74.6939C238.166 74.9044 238.479 74.983 238.768 74.89L242.871 73.5686C244.42 74.4595 246.177 74.9287 247.976 74.9287C253.644 74.9287 258.231 70.3419 258.231 64.6733C258.231 59.0055 253.645 54.418 247.976 54.418ZM243.97 65.6748C244.523 65.6748 244.972 65.2263 244.972 64.6733C244.972 64.1201 244.523 63.6718 243.97 63.6718C243.417 63.6718 242.969 64.1201 242.969 64.6733C242.969 65.2263 243.417 65.6748 243.97 65.6748ZM251.982 65.6748C252.535 65.6748 252.984 65.2263 252.984 64.6733C252.984 64.1201 252.535 63.6718 251.982 63.6718C251.429 63.6718 250.981 64.1201 250.981 64.6733C250.981 65.2263 251.429 65.6748 251.982 65.6748ZM248.978 64.6733C248.978 65.2263 248.529 65.6748 247.976 65.6748C247.423 65.6748 246.975 65.2263 246.975 64.6733C246.975 64.1201 247.423 63.6718 247.976 63.6718C248.529 63.6718 248.978 64.1201 248.978 64.6733Z"
        fill={`${color1}80`}
      />
      <rect
        x="15.4546"
        y="173.85"
        width="275.455"
        height="2.72727"
        rx="1.36364"
        fill={color1}
      />
      <rect
        width="43.6364"
        height="1.81818"
        rx="0.909091"
        transform="matrix(1 0 0 -1 0 145.668)"
        fill={color1}
      />
      <rect
        width="21.8182"
        height="0.909091"
        rx="0.454546"
        transform="matrix(1 0 0 -1 35.4545 42.9412)"
        fill={color1}
      />
      <rect
        width="43.6364"
        height="1.81818"
        rx="0.909091"
        transform="matrix(1 0 0 -1 242.727 92.9412)"
        fill={color1}
      />
      <rect
        width="26.3636"
        height="1.81818"
        rx="0.909091"
        transform="matrix(1 0 0 -1 30.9091 132.941)"
        fill={color1}
      />
      <rect
        width="26.3636"
        height="1.81818"
        rx="0.909091"
        transform="matrix(1 0 0 -1 273.636 80.2139)"
        fill={color1}
      />
    </svg>
  );
}

export default AppsIntegrations;
